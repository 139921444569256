<template>
    <div>
        <b-collapse class="shadow-none" animation="slide" v-for="(item, index) of data" :key="index" :open="isOpen === index" @open="isOpen = index">
            <div slot="trigger" slot-scope="props" class="card-header shadow-none" role="button">
                <div  class="card-header-title flex p-1">
                    <b-image :src="item.Image" style="width: 30px; height: 30px;"></b-image>
                    <p class="ml-5 font-normal">
                        {{ item.Name }}
                    </p>
                </div>
                <a class="card-header-icon p-1">
                    <b-icon
                        :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                </a>
            </div>
            <div class="card-content">
                <div class="content">
                    <viewer :initialValue="item.Description" class="mb-8" />
                    <b-button @click="selectItem(item)">PAYMENT</b-button>
                </div>
            </div>
        </b-collapse>
        <input type="hidden" name="PaymentMethod" :value="selectedItem.Oid">
    </div>
</template>

<script>
import { Viewer } from '@toast-ui/vue-editor'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
export default {
    components: {
        viewer: Viewer
    },
    props: {
        data: {
            type: Array
        }
    },
    data () {
        return {
            companyLocal: JSON.parse(localStorage.getItem('company')),
            isOpen: -1,
            selectedItem: {}
        }
    },
    methods: {
        async selectItem (item) {
            localStorage.setItem('backHome2', JSON.stringify(true))
            var data = this.getData('step')
            this.selectedItem = item
            if (item.Name === 'Visa Card') {
                var response = await this.$baseApi({
                    method: 'POST',
                    url: '/booking/cc',
                    data: data,
                    params: {
                        id: this.$route.query.Oid,
                        v: 1234
                    }
                })
                var tokenAPI = response.token
                // eslint-disable-next-line no-undef
                var handler = await StripeCheckout.configure({
                    // 'pk_test_3HBGvpLtVQofx3jvkYusnM45'
                    key: this.companyLocal.Setting.StripeKey,
                    image: this.companyLocal.Setting.Image,
                    allowRememberMe: false,
                    locale: 'auto',
                    billingAddress: true,
                    zipCode: true,
                    token: async (token) => {
                        await this.$baseApi({
                            method: 'POST',
                            url: '/booking/cc_payment',
                            data: {
                                token: token.id,
                                id: tokenAPI
                            }
                        })
                        this.$router.push({
                            path: '/item/success'
                        })
                    }
                })
                await handler.open({
                    name: 'Ecommerce',
                    description: response.description,
                    currency: response.currency,
                    amount: response.amount
                })
                // return false
            } else if (item.Name === 'BCA Virtual Account') {
                var data2 = this.getData('step')
                data2.PaymentMethod = this.selectedItem.Oid
                await this.$baseApi({
                    method: 'POST',
                    url: '/booking/' + this.$route.query.Oid + '/pay',
                    data: data2
                })
                this.$router.push({
                    path: '/item/successva?Oid=' + this.$route.query.Oid + '&idpay=' + data2.PaymentMethod
                })
            } else {
                setTimeout(() => {
                    this.$emit('payment')
                }, 300)
            }
        }
    }
}
</script>
